import Confetti from "react-confetti"
import { addPropertyControls, ControlType } from "framer"

export default function Confettis(props) {
    let sizeC = props.scaleC * 200
    let xOrigin = (sizeC / 100) * props.xOrigin
    let yOrigin = (sizeC / 100) * props.yOrigin
    return (
        <div style={{ width: sizeC, height: sizeC }}>
            <Confetti
                style={{ width: "100%", height: "100%" }}
                confettiSource={{ x: xOrigin, y: yOrigin, w: 0, h: 0 }}
                width={sizeC}
                height={sizeC}
                numberOfPieces={props.pieceNumber}
                friction={props.friction}
                wind={props.wind}
                gravity={props.gravity}
                colors={props.colors}
                opacity={props.opacityC}
                recycle={props.recycle}
                run={props.run}
            />
        </div>
    )
}

addPropertyControls(Confettis, {
    scaleC: {
        type: ControlType.Number,
        title: "Scale",
        defaultValue: 1,
        min: 0,
        max: 10,
        step: 0.1,
        displayStepper: false,
    },

    xOrigin: {
        type: ControlType.Number,
        title: "x Origin",
        defaultValue: 50,
        min: 0,
        max: 100,
        step: 1,
        displayStepper: false,
    },
    yOrigin: {
        type: ControlType.Number,
        title: "y Origin",
        defaultValue: 50,
        min: 0,
        max: 100,
        step: 1,
        displayStepper: false,
    },
    pieceNumber: {
        type: ControlType.Number,
        title: "Pieces",
        defaultValue: 100,
        min: 0,
        step: 1,
        displayStepper: true,
    },
    friction: {
        type: ControlType.Number,
        title: "Friction",
        defaultValue: 0.99,
        min: 0,
        max: 1,
        step: 0.1,
        displayStepper: true,
    },
    wind: {
        type: ControlType.Number,
        title: "Wind",
        defaultValue: 0,
        min: 0,
        max: 0.99,
        step: 0.01,
        displayStepper: true,
    },
    gravity: {
        type: ControlType.Number,
        title: "Gravity",
        defaultValue: 0.1,
        min: 0,
        max: 0.99,
        step: 0.01,
        displayStepper: true,
    },
    colors: {
        type: ControlType.Array,
        control: {
            type: ControlType.Color,
        },
        defaultValue: ["pink", "yellow", "purple"],
    },
    recycle: {
        type: ControlType.Boolean,
        title: "Recycle",
        defaultValue: true,
        enabledTitle: "On",
        disabledTitle: "Off",
    },
    run: {
        type: ControlType.Boolean,
        title: "Run",
        defaultValue: true,
        enabledTitle: "On",
        disabledTitle: "Off",
    },
})
